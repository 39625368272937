<template>
  <div>
    <section class="section is-main-section">
      <card-component
          :title="
					$route.params.id ? `${form.name} Düzenle` : 'Site Oluştur'
				"
          icon="domain">
        <form @submit.prevent="save">
          <b-field grouped group-multiline>
            <b-field label="Name" expanded>
              <b-input
                  size="is-small"
                  v-model="form.name"
                  icon="domain"
                  placeholder="Name"
                  name="name"
                  required/>
            </b-field>
            <b-field label="Callback URL" expanded>
              <b-input
                  size="is-small"
                  v-model="form.callbackUrl"
                  icon="sitemap"
                  placeholder="Callback URL"
                  name="callbackUrl"
                  required/>
            </b-field>
            <!--
            <b-field label="KK Callback URL" expanded>
              <b-input size="is-small" v-model="form.creditCardCallback" icon="sitemap" placeholder="Callback URL"
                       name="callbackUrl"/>
            </b-field>
            -->
          </b-field>
          <hr/>

          <b-field group-multiline grouped>
            <b-field label="Çekim Komisyonu" expanded>
              <b-input
                  size="is-small"
                  v-model="form.withdrawRate"
                  required/>
            </b-field>
            <!--
            <b-field label="Bakiye Limiti" expanded>
              <b-input
                  size="is-small"
                  v-model="form.balanceLimit"
                  required/>
            </b-field>
            -->
          </b-field>
          <hr/>
          <b-field>
            <b-input type="textarea" size="is-large" v-model="depositSettings"></b-input>
          </b-field>
          <hr/>
          <b-field label="Durum">
            <b-switch size="is-small" v-model="form.status">{{
                form.status ? "Aktif" : "Pasif"
              }}
            </b-switch>
          </b-field>
          <!--
          <b-input size="is-medium" v-model="newMethod" />
          <b-button type="is-success" size="is-medium" icon-left="plus" @click="addNewMethod"/>
          -->
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button
                  native-type="submit"
                  type="is-primary"
                  size="is-small">
                {{ $route.params.id ? "Güncelle" : "Ekle" }}
              </b-button>
            </div>
            <div class="control">
              <b-button
                  type="is-primary is-outlined"
                  size="is-small"
                  @click="$router.go(-1)"
              >Geri Dön
              </b-button
              >
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from "lodash/mapValues"
import CardComponent from "@/components/CardComponent"
import {SiteService as Service} from "@/services"

export default {
  name: "SiteCreateOrUpdate",
  components: {
    CardComponent,
  },
  computed:{
    depositSettings(){
      return JSON.stringify(this.form.depositSettings)
    }
  },
  data() {
    return {
      newMethod: null,
      allSelect: false,
      isLoading: false,
      form: {
        name: null,
        callbackUrl: null,
        withdrawRate: 0,
        depositSettings: {},
        status: true,
        balanceLimit: 0
      },
    }
  },
  mounted() {
    if (this.$route.params.id) this.getData()
  },
  methods: {
    addNewMethod(){
      this.form.depositSettings[this.newMethod] = {depositRate: 0, chatId: '-971121429'}
    },
    getData() {
      Service.edit(this.$route.params.id).then((res) => {
        this.form = {
          uuid: res.data.uuid,
          name: res.data.name,
          balanceLimit: res.data.balanceLimit,
          callbackUrl: res.data.callbackUrl,
          withdrawRate: res.data.withdrawRate,
          status: res.data.status,
          depositSettings: res.data.depositSettings,
        }
      })
    },
    save() {
      this.form.depositSettings = JSON.parse(this.depositSettings)
      Service[this.$route.params.id ? "update" : "create"](this.form)
          .then(() => {
            this.$buefy.toast.open({
              message: this.$route.params.id ? "Güncellendi" : "Eklendi", type: "is-success",
            })
            this.reset()
            this.$router.go(-1)
          })
          .catch((err) =>
              this.$buefy.toast.open({
                message: err.message,
                position: "is-top-right",
                type: "is-danger",
              })
          )
    },
    reset() {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === "object") {
          return []
        }
        return null
      })
    },
  },
}
</script>
